// extracted by mini-css-extract-plugin
export var bestProducts = "style-mod--best-products--2_um9";
export var bestProduct = "style-mod--best-product--2kZNy";
export var bpTitle = "style-mod--bp-title--3eV90";
export var ratingDesc = "style-mod--rating-desc--2Smy4";
export var publisherImg = "style-mod--publisher-img--OhKmN";
export var publisher = "style-mod--publisher--2xLf7";
export var productsDesc = "style-mod--products-desc--2wcaU";
export var clipStar = "style-mod--clip-star--2foms";
export var products = "style-mod--products--2oVPh";
export var allProducts = "style-mod--all-products--1ycI9";