import React from "react";
import { Container, Row, Button } from "react-bootstrap";
import Slider from "react-slick";
import {
  bestProducts, bestProduct,
  bpTitle, clipStar, ratingDesc, publisherImg, publisher,
  productsDesc, products, allProducts
} from "./style.mod.scss";
import getImagePath from "helpers/get-image-path";

const BestProducts = ({ data }) => {

  const { slider, title } = data;

  function PrevButton({ onClick }) {
    return <a onClick={onClick} className="p-left slick-prev" style={{ left: "-50px" }}><i></i></a>;
  }

  function NextButton({ onClick }) {
    return <a onClick={onClick} className="p-right slick-next" style={{ right: "-50px" }}><i></i></a>;
  }

  var settings = {
    infinite: true,
    arrows: true,
    dots: true,
    slidesToShow: 1,
    slidesToScroll:1,
    initialSlide: 1,
    speed: 500,
    prevArrow: <PrevButton />,
    nextArrow: <NextButton />,
    responsive: [
      {
        breakpoint: 767,
        settings: { arrows: false }
      },
    ]
  };
  return (
    <div className={bestProducts}>
      <Container>
        <div className={bestProduct}>
          <p className={bpTitle}>{title}</p>
          <div>
            <div className={clipStar}></div>
            <div className={clipStar}></div>
            <div className={clipStar}></div>
            <div className={clipStar}></div>
            <div className={clipStar}></div>
          </div>
          <p className={ratingDesc}>5.0 (399 Bewertungen)</p>

          <Slider {...settings}>
            {slider.map((s) => {
              return (
                <div key={s._key}>
                  <img className={publisherImg} srcSet={getImagePath(s.reviewerAvatar, true)} alt={s.reviewerAvatar?.asset?.alt}/>
                  <p className={publisher}>{s.reviewerName}</p>
                  <p className={productsDesc}> {s.reviewContent}</p>
                  <Row>
                    <div className={products}>
                      { s.slideImage.map((si, i) => (<img key={i} srcSet={getImagePath(si, true)} alt={si?.asset?.alt}/>)) }
                    </div>
                  </Row>
                </div>
              );
            }
            )}
          </Slider>
          <Button href="/bewertungen" className={allProducts}>Alle Bewertungen</Button>
        </div>
      </Container>
    </div>
  );
};

export default BestProducts;
